import React from "react"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

export default function IntercomChatbotGuide() {
  return (
    <Layout
      keywords="Intercom chatbot integration"
      title="Integrate IrisAgent with your Intercom chatbot"
      description="Documentation on how to integrate IrisAgent with your Intercom chatbot using webhooks."
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/ic-chat-docs/"
        />
      </Helmet>
      {/* <!-- ======= Breadcrumbs ======= --> */}
      <section className="breadcrumbs">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Intercom Chatbot Integration Guide</h2>
            <ol>
              <li>
                <Link to="/ic-chat-docs/">Home</Link>
              </li>
              <li>Intercom Chatbot Guide</li>
            </ol>
          </div>
        </div>
      </section>
      {/* <!-- End Breadcrumbs --> */}
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>

                    <h1>Integrate IrisAgent AI with your Intercom Chatbot</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent AI can answer customer queries right within your Intercom chatbot seamlessly using webhooks. This guide will help you integrate IrisAgent with your Intercom chatbot.
                    <br/>
                    <br/>
                    <h2>
                    Intercom App Configuration
                    </h2>
                    <br/>
                    <h3>
                    Step 1: Create a New Private App
                    </h3>
                    <ol>
                    <li>
                    Navigate to the <a href="https://app.intercom.com/a/apps/_/developer-hub" target="_blank">Developer Hub</a>.
                    </li>
                    <li>
                    Select the option to create a new private app.
                    <img
                src="/img/IntercomDeveloperHub.png"
                style={{
                  width: "80%",
                  padding: "5%",
                }}
                alt="Intercom Developer Hub"
              />
                    </li>
                    </ol>

                    <br/>
                    <h3>
                    Step 2: Set Permissions
                    </h3>
                    <br/>
                    <ol>
                        <li>Access the <code>Authentication</code> menu in your app settings.</li>
                        <li>Configure the necessary permissions as shown below.<br/><br/>
                        <img
                src="/img/IntercomPermissions.png"
                style={{
                  width: "90%",
                }}
                alt="Intercom Permissions"
              />
                        </li>

                    </ol>

                    <br/>
                    <h3>
                    Step 3: Configure Webhooks
                    </h3>
                    <br/>

                    <ol>
                        <li>Go to the <code>Webhooks</code> menu in your app settings.</li>
                        <li>Ensure you set up the following required topics:
                        <ul>
                            <li><code>conversation.user.created</code></li>
                            <li><code>conversation.user.replied</code></li>
                        </ul>

                        </li>
                        <li>Set the Endpoint URL to: <code>https://frontend-api-server-v2.api.irisagent.com/v1/webhooks/intercom/your_organization_id</code>. Note that <code>your_organization_id</code> can be found in the URL of the home page of your Intercom account as shown below:<br/>
                        <code>https://app.intercom.com/a/apps/your_organization_id/home</code>

                        <br/><br/>
                            <img
                               src="/img/IntercomWebhook.png"
                                style={{
                                  width: "90%"
                                }}
                                alt="Intercom Webhooks"
                              />
                        </li>

                    </ol>

                    <br/><br/>
                    <h3>
                    Step 4: Note Your ClientSecret and ClientID
                    </h3>
                    <ol>
                        <li>Find these credentials under the <code>Basic information</code> menu.</li>
                        <li>The ClientSecret is vital for validating webhook requests from Intercom.


                            <img
                               src="/img/IntercomBasicInformation.png"
                                style={{
                                  width: "90%",
                                  padding: "5%",
                                }}
                                alt="Intercom basic information"
                              />
                        </li>

                    </ol>
                    <br/><br/>

                    <br/>
                    <h3>
                    Step 5: Notify IrisAgent
                    </h3>
                    Share the following information with the IrisAgent team <a href="mailto:contact@irisagent.com" target="_blank">by email</a>:
                    <br/><br/>
                    <ol>
                        <li><code>ClientSecret</code> and <code>ClientID</code> from step 4 above.</li>
                        <li><a href="https://developers.intercom.com/docs/references/rest-api/api.intercom.io/Models/contact_reply_intercom_user_id_request/" target="_blank">User id</a> that should be used as the author of IrisAgent AI comments. Your Intercom admin can get the user id for a given user from the Intercom dashboard by following the steps below:
                        <ol>
                            <li>Navigate to Settings -> Teammates.</li>
                            <li>Click on the name of the user whose id you want to get</li>
                            <li>The URL of the page would look like <code>https://app.intercom.com/a/apps/intercom_id/admins/user_id</code>. Share the <code>user_id</code> at the end of the URL.</li>
                        </ol>
                        </li>
                    </ol>

                    <br/><br/>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}
